
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import { MaterialInputTemplate, MaterialItem } from "@/lib/models";

interface Methods {
  getKey: (item: MaterialItem) => string;
  getTitle: (title?: string) => string;
}

interface Comupted {
  templateId: string | null;
  layoutType: string | null;
  detail: string;
  template: MaterialInputTemplate | null;
  items: MaterialItem[];
}

export default Vue.extend<unknown, Methods, Comupted, unknown>({
  components: { ButtonRound },

  computed: {
    templateId() {
      const { params } = this.$route;

      if (params && params.templateId) {
        return params.templateId;
      }

      return null;
    },
    layoutType() {
      const { template } = this;
      if (!template) {
        return null;
      }

      return template.layout;
    },
    detail() {
      return "ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。ここに内容が入ります。";
    },
    template() {
      const { templateId } = this;
      if (!templateId) {
        return null;
      }

      const { getters } = this.$store;
      const template = getters["materialModule/template"](templateId) as MaterialInputTemplate;
      if (!template) {
        return null;
      }

      return template;
    },
    items() {
      const { template, layoutType } = this;
      if (!template || !layoutType) return [];

      const { items } = template;
      return items;
    },
  },

  methods: {
    getKey(item) {
      if (item.id) {
        return item.id;
      }

      return uuidv4();
    },
    getTitle(title) {
      if (title) {
        return title;
      }

      return "項目名が入ります";
    },
  },
});
