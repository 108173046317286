
import Vue, { PropType } from "vue";
import ListTile from "@/components/molecules/lists/ListTile.vue";
import { MaterialInputTemplate } from "@/lib/models";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";

interface Methods {
  onClick: (event: PointerEvent) => void;
  onCheck: (event: PointerEvent) => void;
}

interface Computed {
  itemName: string;
  isActive: boolean;
}

interface Props {
  template: MaterialInputTemplate;
  isListEditing: boolean;
  isDeleteEditing: boolean;
  checked: boolean;
  handleClassName: string;
  addDeleteTemplate: (template: MaterialInputTemplate) => void;
  refresh: () => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: { ListTile, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon },

  props: {
    template: Object as PropType<MaterialInputTemplate>,
    isListEditing: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isDeleteEditing: {
      type: Boolean,
      default: false,
    },
    handleClassName: {
      type: String,
      default: "handle",
    },
    addDeleteTemplate: {
      type: Function,
      default: (_) => {},
    },
    refresh: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    itemName() {
      const { template } = this;
      return template.name;
    },
    isActive() {
      const { $route, template } = this;
      return $route.params.templateId === template.id;
    },
  },

  methods: {
    // event handle (button, checkbox, etc)

    onClick(_) {
      const { $store, $router, $route, template, isListEditing, isDeleteEditing } = this;
      const templateId = template.id;

      if (isListEditing || isDeleteEditing) return;

      // 資料テンプレートIDをindexedDBに格納
      $store.dispatch("materialModule/openTemplate", templateId);

      if (templateId === $route.params.templateId) return;

      const params = { ...$route.params, templateId };
      if ($route.name) {
        $router.push({ name: $route.name, params });
      }
    },
    onCheck(_) {
      const { template, addDeleteTemplate } = this;
      addDeleteTemplate(template);
    },
  },
});
